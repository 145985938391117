@import '../../styles/index.scss';

.playersDropdown {
	padding: 0.825rem 1rem;
}

.customCloseIcon {
	margin: 0 0 0 4px;
	width: 10px;
	height: 10px;
	background: url('../../assets/icons/dropdown-close/dropdown-close.svg');
}

.separator {
	background-color: $grey100;
}
