@import '../../styles/index.scss';

.container {
	background-color: $background;

	h2 {
		margin-top: 2rem;

		+ h4 {
			margin-top: 2rem;
		}
	}
}
