@import '../../styles/index.scss';

.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0 1em;
	border-radius: 8px;
	cursor: pointer;
	text-align: center;
	box-shadow: 1px 2px 4px rgba(9, 26, 59, 0.2);
	overflow: hidden;

	&.primary {
		background: $primary;
		color: $white;
		border: 0;

		&:active {
			background: $primaryLight;
		}

		&:disabled {
			background: $secondaryLight;
		}
	}

	&.secondary {
		background: $background;
		color: $secondary;
		border: 1px solid $secondary;

		&:active {
			background: $grey100;
		}

		&:disabled {
			color: $grey400;
			border: 1px solid $grey400;
			background: $white;
		}
	}

	&.large {
		width: 100%;
		height: 48px;
	}

	&.small {
		height: 40px;
		background: $white;
	}
}

.iconAndTextContainer {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	padding: 0 0.25rem;
	overflow: hidden;
}
