@import '../../styles/index.scss';

.container {
	background-color: $background;
}

.playerName {
	margin-top: 2rem;
}

.mainComponents {
	width: 100%;
	max-width: 570px;
}
