@import '../../styles/index.scss';

$cardWidth: 20rem;

.getStartedButton {
	max-width: ($cardWidth - 2);
	position: absolute;
	bottom: 0;
	margin: 1rem;
}
