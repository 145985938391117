@import '../../styles/index.scss';

.inputContainer {
	position: relative;
	margin-bottom: 8px;
}

.inputContainer label {
	position: absolute;
	color: $secondaryDark;
	left: 10px;
	top: 5px;
}

.playerRowHeader {
	display: flex;
	justify-content: space-between;
	margin: 2.5rem 0 0.5rem 0;
}

.subHeader {
	margin: 24px 0 16px 0;
	color: $secondary;
}

.row {
	display: flex;
	gap: 16px;
}

.col {
	flex-grow: 1;
}

.addAnotherPlayer {
	margin-top: 1rem;
	margin-left: auto;
}

.deletePlayer {
	width: 16px;
	height: 16px;
	background: url('../../assets/icons/trashfull/trashfull.svg');
}

.deleteBtnContainer {
	display: flex;
	color: $primary;
	cursor: pointer;

	span {
		margin-left: 8px;
	}
}

.submitBtn {
	margin-top: 40px;
}
