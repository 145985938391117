.backButton {
	width: 32px;
	cursor: pointer;
}

.white {
	filter: invert(1);
}

.container {
	flex-direction: row;
}
