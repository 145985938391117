@import './styles/index.scss';

.App {
	text-align: center;
	width: auto;
}

// PLAYER DROPDOWN
#multiselectContainerReact {
	margin-top: 0.825rem;
	background: linear-gradient(180deg, $navbarBackgroud 0%, rgba(255, 255, 255, 0) 100%), $navbarBackgroud;
	border: 1px solid $secondaryLight;
	border-radius: 8px;
	flex-grow: 0;
	font-family: $nunitoSansRegular;

	span {
		font-size: $captionSize;
		font-family: $nunitoSansRegular;
		line-height: 18px;
		background: rgb(247, 231, 217);
		border: 1px solid $primary;
		border-radius: 8px;
		color: $secondary;
		margin-left: 5px;
	}

	#search_input {
		font-size: $bodySize;
		font-family: $nunitoSansRegular;
		line-height: 22px;
		height: 36px;
		border: none;
		margin-top: 3px;
		padding: 4px 10px;
		margin-right: 5px;
		margin-bottom: 5px;
		display: inline-flex;
		align-items: center;
	}

	.searchWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}
