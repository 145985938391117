@import '../../styles/index.scss';

.dropdown {
	width: 100%;
	height: 56px;
	color: $secondary;
	background-color: white;
	border: 1px solid $secondaryLight;
	border-radius: 8px;
	padding-left: 5px;
}

.dropdownPlaceholder {
	color: $secondary;
}

.dropdownSelected {
	color: $secondary;
}
