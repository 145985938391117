@import '../../styles/index.scss';

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 2rem 2rem 0 2rem;
	padding: 2rem 2rem 1rem 2rem;
	text-align: center;
}

.facilitiesSection {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.facilityOptionContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	padding: 1rem 1rem 1rem 0.5rem;
	background: $white;
	border-radius: 8px;
	box-shadow: 4px 2px 12px 2px rgba(4, 19, 46, 0.05);
	width: fit-content;
	min-width: 366px;
	gap: 0.5rem;
	cursor: pointer;
}

.facilityNameAndAddressContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.facilityOptionDescription {
	display: flex;
	flex-direction: column;
}

.selectFacilityButton {
	margin-top: 3rem;
	max-width: 366px;
}

.facilityIcon {
	width: 50px;
	height: 50px;
	margin-right: 1rem;
}

.subTitle {
	padding: 1rem;
}
