@import '../../styles/index.scss';

.navbar {
	width: auto;
	height: 72px;
	background: linear-gradient(180deg, $navbarBackgroud 0%, rgba(255, 255, 255, 0) 100%), $navbarBackgroud;
	box-shadow: 2px 2px 12px 2px rgba(4, 19, 46, 0.05);
	padding: 0 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
	z-index: 1;
	position: relative;
}
