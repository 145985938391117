@import '../../styles/index.scss';

.accordionContainer {
	display: flex;
	flex-direction: column;
}

.accordionButton {
	text-decoration-line: none;
	padding: 1.25rem 1.25rem 0.8rem 1.25rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		transition: transform 0.4s ease;
	}
}

.rotate {
	transform: rotate(180deg);
}

.accordionContent {
	overflow: hidden;
	transition: max-height 0.4s ease;
}
