@import '../../../styles/index.scss';

form {
	margin-top: 4em;
}

.submitBtn {
	margin-top: 4em;
	margin-bottom: 2em;
}

.circleCheckIcon {
	width: 88px;
	height: 88px;
	border-radius: 50%;
	background: rgba($primary, 0.15) url(../../../assets/icons/circle-check/circle-check.svg) no-repeat center;
	align-self: center;
	margin-top: 3rem;
}

.circleCrossIcon {
	width: 88px;
	height: 88px;
	border-radius: 50%;
	background: rgba($primary, 0.15) url(../../../assets/icons/circle-cross/circle-cross.svg) no-repeat center;
	align-self: center;
	margin-top: 3rem;
}
