@import '../../styles/index.scss';

.bottomSectionContainer {
	width: 100%;
	height: 15%;
	color: $secondaryDark;
	background-color: $background;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 0.5rem;
	padding-top: 1rem;
	padding-bottom: 3rem;

	button {
		color: $primary;
		margin-bottom: 1rem;
		text-decoration-line: none;
	}
}
