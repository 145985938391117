@import '../../styles/index.scss';

.membershipBenefitsListContainer {
	padding: 0.5rem 1rem;
}

.benefitItemContainer {
	display: flex;

	&:not(:last-child) {
		margin-bottom: 0.875rem;
	}
}

.benefitItemIcon {
	width: 1rem;
	height: 1rem;
	background: url('../../assets/icons/membershiptBenefitItem/membershipBenefitItem.svg') no-repeat center;
	margin: 0.125rem 0.75rem 0 0;
}
