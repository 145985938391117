@import '../../styles/index.scss';

.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 80px;
}

.previousSectionName {
	flex-direction: row;
	justify-content: center;
	display: flex;
	align-content: center;
	margin-top: 2rem;
	margin-bottom: 0;
}

.backbutton {
	width: 32px;
}

.text {
	padding-left: 25px;
}

.separator {
	display: flex;
	align-self: flex-start;
	margin-top: 0.4rem;
	margin-left: 57px;
	width: 24px;
	height: 2px;
	background-color: $primary;
}
