@import '../../../styles/index.scss';

.infoContainer {
	background-color: $white;
	padding: 1.7rem 1.5rem;
}

.chevron {
	margin-right: 0.6rem;
	height: 12px;
	transform: rotate(90deg);

	path {
		fill: $secondary;
	}
}

.playerCard {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 2px 2px 12px 2px rgba(4, 19, 46, 0.05);
	border-radius: 8px;
	background-color: $white;
	border: none;
	height: 3.5rem;
	text-transform: capitalize;
	cursor: pointer;
	margin: 1.2rem 0;
	padding: 0 1.2rem;
	width: 100%;

	span {
		display: flex;
		align-items: center;
		color: $secondary;
		overflow: hidden;
		padding-right: 1rem;

		svg {
			margin-right: 0.6rem;

			path {
				fill: $primary;
			}
		}
	}
}

.billingAndPaymentCard {
	margin: 1.2rem 0 2.5rem;
}

.emptyData {
	margin-top: 1rem;
}

.noBillingInformationContainer {
	display: flex;
	flex-direction: row;
	margin-top: 1.2rem;
}

.noBillingInformationImage {
	width: 60px;
	height: 60px;
}

.noBillingInformationText {
	display: flex;
	align-items: center;
	margin-left: 1.2rem;
}

.logoutButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 2px 2px 12px 2px rgba(4, 19, 46, 0.05);
	border-radius: 8px;
	background-color: $white;
	border: none;
	height: 3.5rem;
	cursor: pointer;
	margin: 1.2rem 0;
	padding: 0 1.2rem;
	width: 90%;
}

.logoutText {
	color: $secondary;
}

.coolicon {
	margin-right: 0.6rem;
	height: 18px;
}

.deleteAccountButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.thrashfull {
	width: 20px;
	height: 20px;
}

.playerButtonText {
	overflow: hidden;
	text-overflow: ellipsis;
}
