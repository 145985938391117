@import '../../styles/index.scss';

$cardWidth: 20rem;

.screenContainer {
	height: 100%;
}

.container {
	flex-grow: 1;
	color: $white;
	display: flex;
	flex-direction: column;
}

.mainSectionContainer {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.78) 40.76%, rgba(0, 0, 0, 0.14) 100%), 
	url('../../assets/images/landing-background/landing-background.png') center no-repeat;
	background-size: cover;
	text-align: center;
	height: 100%;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	p {
		margin-top: 1rem;
	}
}

.textWrapper {
	padding: 1rem 2rem;

	h2 {
		margin-bottom: 1rem;
	}
}

.btnContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	height: 25%;
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.form {
	margin-top: 0;
	margin-bottom: 2em;
}

.submitBtn {
	margin-top: 1em;
}

.RecoverPasswordContainer {
	text-align: center;
	margin-top: auto;
	margin-bottom: 2rem;
}

.recoverPasswordLink {
	font-family: $nunitoSansBold;
	font-size: $linkSize;
	line-height: 22px;
	color: $primaryLightest;
	text-decoration-line: underline;
	cursor: pointer;
	background-color: transparent;
	border: none;
}

.viewMembershipsContainer {
	background: linear-gradient($secondary 25rem, $background 25rem);
	color: $grey800;
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;
	padding: 2rem;

	h1 {
		color: $white;
		text-align: center;
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4rem 0 8rem;
}

.membershipCards {
	background-color: $background;
	width: $cardWidth;
	position: relative;
	padding-bottom: 4rem;
}

.membershipsSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 1.5rem;

	.dropdown {
		width: 95%;
		max-width: $cardWidth;
	}
}

.membershipPlansContainer {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	width: auto;
	gap: 2rem;
}

.alertBanner {
	position: relative;
	width: 100%;
}
