@import '../../../styles/index.scss';

form {
	margin-top: 4em;
}

.submitBtn {
	margin-top: 4em;
	margin-bottom: 2em;
}

.emailIcon {
	width: 88px;
	height: 88px;
	border-radius: 50%;
	background: rgba($primary, 0.15) url(../../../assets/icons/email/email.svg) no-repeat 27px 30px;
	align-self: center;
	margin-top: 3rem;
}
