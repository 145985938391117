@import '../../styles/index.scss';

.container {
	background-color: $background;
	padding: 4rem 0 2rem 0;
	gap: 1.5rem;
}

.promoCodeContainer {
	background-color: $white;
	padding: 2rem;
}

.separator {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.title {
	margin-left: 2.5rem;
}

.dialogBody {
	text-align: center;
}
