@import '../../styles/index.scss';

.promoCodeContainer {
	padding: 1rem 1rem 0;
	background: $white;
}

.promoCodeForm {
	margin: 1rem 0;
}

.trashIcon {
	cursor: pointer;
}

.input {
	text-transform: uppercase;
}

.inputContainer {
	width: 80%;
}

.messageContainer {
	position: absolute;
}
