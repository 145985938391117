@import '../../styles/index.scss';

.container {
	display: flex;
	align-items: center;
	padding-top: 30px;
}

.nowrap {
	white-space: nowrap;
}
