@import '../../styles/index.scss';

.inputContainer {
	position: relative;
	margin-bottom: 8px;
}

.inputContainer label {
	position: absolute;
	pointer-events: none;
	transform: translate(3px, 28px) scale(1);
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: $secondaryDark;
	left: 7px;
}

.inputContainer:focus-within label {
	transform: translate(3px, 12px) scale(0.9);
}

.inputContainer .filled {
	transform: translate(3px, 12px) scale(0.9);
}

.inputContainer input {
	padding: 10px 10px 0 10px;
	outline: none;
	box-shadow: none;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	width: 100%;

	&:-webkit-autofill + label {
		transform: translate(3px, 12px) scale(0.9);
	}
}

.inputContainer input:focus {
	background: linear-gradient(180deg, $navbarBackgroud 0%, rgba(255, 255, 255, 0) 100%), $navbarBackgroud;
	border: 1px solid $primary;
	box-sizing: border-box;
	border-radius: 8px;
}
