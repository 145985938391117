@import '../../styles/index.scss';

.errorMessage {
	display: flex;
	flex-direction: row;
	margin-left: 5px;
	color: $error;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.alertTriangle {
	width: 16px;
	height: 16px;
}
