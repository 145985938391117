@import '../../styles/index.scss';

.container {
	display: flex;
	justify-content: center;
	background-color: $background;
	padding: 4rem 0 2rem 0;

	h2 {
		padding: 0 1.5rem;
		margin-bottom: 2rem;
	}
}

.mainComponents {
	width: 100%;
	max-width: 570px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.disclaimerText {
	width: 17rem;
	text-align: center;
}

.customFeedbackText {
	width: 18rem;
	text-align: left;
	margin-bottom: 1rem;
}

.buttonContainer {
	padding-top: 2rem;
	width: 18rem;
	height: 9rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.reasons {
	margin-top: 1rem;
	align-items: flex-start;
	justify-content: flex-start;
	width: 17rem;
	color: $grey800;
}

.separator {
	width: 85%;
	max-width: 362px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.customMessage {
	height: 10rem;
	justify-content: flex-start;
	align-items: flex-start;
	inline-size: 17rem;
	padding: 1rem;
	border-radius: 15px;
	border-width: 0;
}
