@import '../../../styles/index.scss';

.inputContainer {
	position: relative;
	margin-bottom: 8px;
}

.inputContainer label {
	position: absolute;
	color: $secondaryDark;
	left: 10px;
	top: 5px;
}

.infoMessageContainer {
	margin: 16px 0 50px 0;
}

.subHeader {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

.row {
	display: flex;
	gap: 16px;
}

.col {
	flex-grow: 1;
}

.secondaryBtn {
	width: 200px;
	margin-top: 16px;
}

.btnNoLink {
	border: none;
	box-shadow: none;
	margin: 0;
	color: $primary;
}

.btnNoLink:focus {
	border: none;
	outline: none;
}

.subHeaderContainer {
	margin: 10px 16px;
}

.verticalSeparator {
	z-index: 1;
	transform: rotate(90deg);
	width: 40px;
	position: absolute;
	margin: 25px 0 0 300px;
	border: 1px solid $grey100;
}

.assignMembershipBtn {
	margin-top: 16px;
}

.doItLaterLinkContainer {
	text-align: center;
	margin: 32px;
}

input[type="checkbox"] {
	filter: hue-rotate(180deg);
}

.membershipPlansContainer {
	margin-bottom: 3rem;

	.membershipPlan {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}

.submitBtn {
	margin-top: 40px;
}

.bottomLinks {
	text-align: center;
	padding: 0 1rem;
}
