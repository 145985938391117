@import '../../../styles/index.scss';

.infoContainer {
	background-color: $white;
	padding: 1.7rem 1.5rem;
}

.sectionContainer {
	padding: 0 1.5rem;
}

.sectionSeparator {
	height: 12px;
	background-color: $background;
}

.action {
	margin-top: 4rem;
}

.billingDetails {
	background-color: $white;
	padding: 0 1.5rem;
	margin-top: 2rem;
}

.disclaimer {
	color: $grey600;
	margin-top: 1.25rem;
}
