@import '../../../styles/index.scss';

.deletePlayerBtnContainer {
	display: flex;
	justify-content: center;

	.deletePlayerContentWrapper {
		display: inline-flex;
		align-items: center;
		margin-top: 0.5rem;
		cursor: pointer;

		img {
			margin-right: 0.5rem;

			+ span {
				color: $primary;
			}
		}
	}
}
