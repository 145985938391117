@import '../../../styles/index.scss';

.disclaimerContainer {
	padding: 2rem;
	display: flex;
	flex-direction: row;
}

.disclaimerIcon {
	margin-right: 0.5rem;
}
