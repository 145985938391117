@import '../../styles/index.scss';

.input {
	width: auto;
	height: 56px;
	color: $secondary;
	border: 1px solid $secondaryLight;
	box-sizing: border-box;
	border-radius: 8px;
	margin: 8px 0;
	flex-grow: 1;
}
