@import-normalize;

@import './colors.scss';
@import './typographies.scss';

@font-face {
	font-family: 'NunitoSans-Bold';
	src: local('NunitoSans-Bold'), url(../assets/fonts/NunitoSans-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'NunitoSans-Regular';
	src: local('NunitoSans-Regular'), url(../assets/fonts/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'NunitoSans-SemiBold';
	src: local('NunitoSans-SemiBold'), url(../assets/fonts/NunitoSans-SemiBold.ttf) format('truetype');
}

.megaHeader {
	font-family: $nunitoSansBold;
	font-size: $megaHeaderSize;
	line-height: 38px;
}

.header1 {
	font-family: $nunitoSansBold;
	font-size: $headerLargeSize;
	line-height: 30px;
}

.header2 {
	font-family: $nunitoSansBold;
	font-size: $headerRegularSize;
	line-height: 26px;
}

.header3 {
	font-family: $nunitoSansRegular;
	font-size: $headerRegularSize;
	line-height: 26px;
}

.subheader {
	font-family: $nunitoSansBold;
	font-size: $subheaderSize;
	line-height: 24px;
}

.body1 {
	font-family: $nunitoSansRegular;
	font-size: $bodySize;
	line-height: 22px;
}

.body2 {
	font-family: $nunitoSansBold;
	font-size: $bodySize;
	line-height: 22px;
}

.caption1 {
	font-family: $nunitoSansRegular;
	font-size: $captionSize;
	line-height: 18px;
}

.caption2 {
	font-family: $nunitoSansBold;
	font-size: $captionSize;
	line-height: 18px;
}

.buttonText {
	font-family: $nunitoSansBold;
	font-size: $buttonSize;
	text-transform: uppercase;
	line-height: 20px;
}

.linkText {
	font-family: $nunitoSansSemiBold;
	font-size: $linkSize;
	text-decoration-line: underline;
	line-height: 17px;
	color: $primary;
	cursor: pointer;
	background-color: transparent;
	border: none;
}

.inputText {
	font-family: $nunitoSansRegular;
	font-size: $inputSize;
}

$smallScreenWidth : 570px;
