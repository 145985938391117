@import '../../../../styles/index.scss';

.header {
	margin-top: 10px;
	color: $white;
	text-align: center;
	white-space: pre-wrap;
}

.description {
	margin-top: 16px;
	color: $white;
	text-align: center;
	white-space: pre-wrap;
}

.btnSection {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	width: 20rem;
}

.transitionContainer {
	background: $secondary;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4rem;
}
