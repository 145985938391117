@import '../../styles/index.scss';

.discountLabelContainer {
	background-color: $successLight;
	color: $success;
	border-radius: 1rem;
	padding: 0.25rem 0.75rem;
}

.detailPricesContainer {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
	gap: 1.5rem;
}

.detailText {
	color: $secondaryDark;
}

.priceText {
	color: $secondary;
}

.billingLineContainer {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}

.disclaimerIcon {
	width: 1rem;
	height: 1rem;
	background: url('../../assets/icons/disclaimer/disclaimer.svg') no-repeat center;
	margin: 0 0.5rem 0 0;
	border: $background;
}

.disclaimerAndPriceContainer {
	display: flex;
	flex-direction: row;
}
