@import '../../styles/index.scss';

.container {
	padding: 4rem 0 2rem 0;
	background-color: $background;

	h2 {
		padding: 0 1.5rem;
		margin-bottom: 2rem;
	}

	.actionsContainer {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-items: center;

		a {
			display: inline-block;
		}
	}
}

.infoSections {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.separator {
	width: 90%;
	background-color: $grey200;
}
