@import '../../styles/index';

.container {
	background-color: $white;
	margin-top: 1.8rem;
}

.sectionContainer {
	padding: 1rem 0;
}

.subtitle {
	margin: 1rem 0;
}

.playersContainer {
	display: flex;
	align-items: baseline;
}

.sectionSeparator {
	height: 12px;
	background-color: $background;
}

.linethrough {
	text-decoration: line-through;
}

.paymentOptionForm {
	margin: 1rem 0;
}

.submitBtn {
	margin-top: 50px;
}
