@import '../../styles/index.scss';

.input input {
	margin-top: 10px;
	padding-left: 10px;
	padding-bottom: 6px;
	background: linear-gradient(180deg, $navbarBackgroud 0%, rgba(255, 255, 255, 0) 100%), $navbarBackgroud;
	font-family: "NunitoSans-Regular", sans-serif;
	font-size: 16px;
	line-height: 22px;
}

.input fieldset {
	border-radius: 8px;
	border-color: $secondaryLight !important;
	border-width: 1px;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.focused {
	fieldset {
		border-width: 1px !important;
		border-color: $primary !important;
	}
}
