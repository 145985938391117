@import '../../../styles/index.scss';

.questionList {
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-top: 1px solid $secondaryLight;
	padding: 1rem;
	gap: 2rem;
}

.accordion {
	color: $secondaryDark;
	display: flex;
	justify-content: space-between;
}

.chevron {
	path {
		fill: $secondary;
	}
}

.card {
	margin-top: 1rem;
	background: $white;
	border: 1px solid $secondaryLight;
	border-radius: 8px;
	box-shadow: 1px 2px 4px rgba(9, 26, 59, 0.2);
}

.title {
	color: $secondary;
	margin-bottom: 0.5rem;
}

.answer {
	color: $secondaryDark;
}

.caption {
	color: $secondaryDark;
}

.searchBox {
	background: $grey100;
	border-radius: 8px;
}

.autoComplete {
	width: 70%;
	margin-top: 2rem;
	margin-bottom: 2rem;
}
