@import '../../styles/index.scss';

.container {
	display: flex;
	flex-direction: column;
}

.input {
	width: auto;
	height: 56px;
	color: $secondary;
	border-style: none;
	margin-left: 0.5rem;
	flex-grow: 1;
}

.input:focus {
	outline: none;
}

.inputContainer {
	display: flex;
	border: 1px solid $secondaryLight;
	box-sizing: border-box;
	border-radius: 8px;
	margin: 8px 0;
	background: $white;
	width: 100%;
}

.disabledContainer {
	background: $grey100;
}

.inputContainer:focus-within {
	border-color: $primary;
}

.rightSection {
	display: flex;
	margin-right: 1rem;
	margin-left: 1rem;
}

.rightSection:hover {
	cursor: pointer;
}
