@import '../../styles/index.scss';
@import '../../styles/colors';

.membershipSection {
	margin-top: 1.8rem;
	padding: 1.7rem 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.addMembershipText {
	margin-top: 0.6rem;
	height: 52px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.trashIcon {
	width: 27px;
	height: 30px;
	align-self: center;

	path {
		fill: $secondary;
	}
}

.loopIcon {
	width: 16px;
	height: 16px;
	align-self: center;

	path {
		fill: $secondary;
	}
}

.noMembershipContainer {
	display: flex;
}

.noMembershipImage {
	width: 88px;
	height: 88px;
}

.noMembershipText {
	margin-left: 1.2rem;
	display: flex;
	align-items: center;
}
