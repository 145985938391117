@import '../../styles/index.scss';

.separator {
	background-color: $grey100;
}

.container {
	background-color: $background;
	padding: 4rem 1.5rem;

	h2,
	h4,
	p,
	.separator {
		margin-bottom: 1rem;
	}

	ul {
		list-style: none;

		li {
			margin-bottom: 0.75rem;
			margin-left: 1rem;

			&::before {
				content: "\2022";
				color: $primary;
				font-size: 1.3rem;
				display: inline-block;
				width: 1.2rem;
				margin-left: -1.2rem;
			}

			&:last-child {
				margin-bottom: 1rem;
			}
		}
	}
}
