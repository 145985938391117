@import '../../styles/index.scss';

.container {
	padding: 1.5rem;
	flex-grow: 1;
	color: $secondary;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	h4 {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}

	p {
		line-height: 22px;
	}
}
