@import '../../styles/index.scss';

.screenContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.container {
	padding: 4rem;
	flex-grow: 1;
	color: $secondary;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h4 {
		margin-top: 3rem;
		margin-bottom: 0.5rem;
	}
}
