@import '../../styles/index.scss';

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	background-color: $successLight;
	border-radius: 8px;
}

.infoMessage {
	display: flex;
	flex-direction: row;
	margin: 10px;
	color: $secondary;
	width: fit-content;
}

.feedback {
	align-self: baseline;
	margin: 10px 0 10px 10px;
	padding-right: 1px;
	width: 16px;
	height: 16px;
	background: url('../../assets/icons/feedback/feedback.svg');
	background-repeat: no-repeat;
	background-size: cover;
}
