@import '../../styles/index.scss';

.pricingInfoContainer {
	padding: 0.5rem 1rem;
}

.pricingRecurringText {
	color: $secondaryInput;
	margin-left: 0.75em;
}

.pricingText {
	margin: 5px;
}
