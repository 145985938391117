@import '../../styles/index.scss';

.clickableContainer {
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.separator {
	background-color: $grey200;
}

.statusActiveCard {
	border: 1px solid $success;
	border-radius: 8px;
}

.statusCancelledCard {
	border: 1px solid $warning;
	border-radius: 8px;
}

.statusActiveAccordionButton {
	color: $feedbackSuccess;

	svg path {
		fill: $feedbackSuccess;
	}
}

.statusCancelledAccordionButton {
	color: $primary;

	svg path {
		fill: $primary;
	}
}

.statusActive {
	background-color: $successLight;
	color: $feedbackSuccess;
	padding: 8px 24px;
	border-radius: 8px 8px 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.statusCancelled {
	background-color: $warningLight;
	color: $primary;
	padding: 8px 24px;
	border-radius: 8px 8px 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.expirationDate {
	display: flex;

	img {
		margin-right: 7px;
		width: 16px;
		height: 16px;
	}
}
