@import '../../styles/index.scss';

.mainHeaderContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1rem;
}

.headerInformationContainer {
	display: flex;
	flex-direction: row;
}

.headerTextContainer {
	display: flex;
	flex-direction: column;

	p {
		color: $secondaryInput;
	}

	h2 {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.membershipHeaderCheckboxContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: auto;
}

.optionalIcon {
	padding: 0 0.5rem;
}
