@import '../../../../styles/index.scss';

.header {
	color: $white;
	text-align: center;
	white-space: pre-wrap;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4rem;
}

.mainSection {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 10px 5px;
}

.mainSectionMemberships {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 10px 20px 80px 20px;
}

.membershipDescContainer {
	display: flex;
	flex-direction: column;
	margin: 5px 0 0 5px;
}

.membershipIcon {
	width: 42px;
	height: 47px;
	margin: inherit;
}

.membershipType {
	color: $secondaryInput;
}

.kidIcon {
	width: 16px;
	height: 16px;
	background: url('../../../../assets/icons/kid/kid.svg');
}

.clarificationSection {
	display: flex;
	flex-direction: row;
	margin-left: 20px;
}

.clarificationText {
	color: $secondaryInput;
}

.pricingInfoContainer {
	display: flex;
	margin: 0.6em 1em;
}

.discountSection {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: $successLight;
	border-radius: 12px;
	height: 24px;
	padding: 10px;
}

.membershipBenefitsListContainer {
	display: flex;
	flex-direction: column;
	margin: 10px;
}

.benefitItemContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.pricingRecurringText {
	color: $secondaryInput;
	margin-left: 0.75em;
}

.pricingOriginalPrice {
	text-decoration-line: line-through;
}

.pricingDiscount {
	color: $feedbackSuccess;
}

.pricingText {
	margin: 5px;
}

.benefitItemIcon {
	width: 16px;
	height: 16px;
	margin: 5px;
	background: url('../../../../assets/icons/membershiptBenefitItem/membershipBenefitItem.svg') no-repeat;
}

.benefitItemText {
	margin: 5px;
}

.baseScreenBtn {
	margin-top: 50px;
}
