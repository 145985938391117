@import '../../styles/index.scss';

.sectionRow {
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
}

.textContainer {
	overflow: hidden;
	text-overflow: ellipsis;
}

.rowName {
	margin-right: 1rem;
}
