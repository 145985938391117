@import '../../styles/index.scss';

.container {
	display: flex;
	justify-content: center;
	padding: 2rem 1.5rem;
}

.stepContainer {
	margin: 2rem 0;
}

.mainDesc {
	display: block;
	width: 34ch;
	margin: 1rem 0;
}

.loginText {
	padding-right: 0.5rem;
}

.linksContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.transition {
	height: 90vh;
	background: $secondary;
}
