@import '../../styles/index.scss';

.container {
	display: flex;
	justify-content: center;
	padding: 2rem 1.5rem;
}

.box {
	width: 100%;
	max-width: $smallScreenWidth;
}
