@import '../../styles/index.scss';

.successMessage {
	display: flex;
	flex-direction: row;
	margin-left: 5px;
	color: $success;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.greenTick {
	width: 16px;
	height: 16px;
	background: url('../../assets/icons/green-tick/green-tick.png');
}
