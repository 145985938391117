@import '../../styles/index.scss';

.backgroundContainer {
	background: linear-gradient($secondary 7.5rem, $background 7.5rem);
}

.box {
	display: flex;
	align-items: center;
	flex-direction: column;
}
