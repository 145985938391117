@import '../../styles/index.scss';

.sectionContainer {
	padding: 1rem;
}

.subtitle {
	margin: 1rem 0;
}

.totalContainer {
	margin: 1rem 0.75rem 1rem 0;
	display: flex;
	justify-content: space-between;
}

.billingDetailsContainer {
	padding: 1rem;
}
