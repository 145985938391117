@import '../../styles/index.scss';

.sectionTitleFirstRow {
	display: flex;
	justify-content: space-between;
}

.sectionTitle {
	h4 {
		margin-bottom: 0.6rem;
	}

	button {
		background-color: transparent;
		border: none;
	}
}
