@import '../../../styles/index.scss';

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1.5rem;
}

.dialogTitleText {
	text-align: center;
}

.bodyContainer {
	padding: 1rem 0;
}

.buttonsContainer {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}
