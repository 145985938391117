//font families
$nunitoSansBold: 'NunitoSans-Bold';
$nunitoSansRegular: 'NunitoSans-Regular';
$nunitoSansSemiBold: 'NunitoSans-SemiBold';

//font sizes
$megaHeaderSize: 32px;
$headerLargeSize: 26px;
$headerRegularSize: 21px;
$subheaderSize: 18px;
$bodySize: 16px;
$buttonSize: 16px;
$linkSize: 16px;
$inputSize: 16px;
$captionSize: 14px;
